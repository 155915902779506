import React from "react"
import { Link } from "gatsby"

export interface PaginationProps {
  totalPages: number
  createURL: (pageNumber: number) => string

  currentPage: number
  className?: string
}

function getPagingRange(current, { min = 1, total = 20, length = 5 } = {}) {
  if (length > total) length = total

  let start = current - Math.floor(length / 2)
  start = Math.max(start, min)
  start = Math.min(start, min + total - length)

  return Array.from({ length: length }, (el, i) => start + i)
}

export const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  createURL,
  className,
}) => {
  return (
    <ul className={`flex ${className}`}>
      {getPagingRange(currentPage, { total: totalPages }).map(pageNumber => {
        const isActive = pageNumber === currentPage
        const isActiveClassName = isActive && "text-gray-200 bg-gray-700"
        return (
          <Link key={pageNumber} to={createURL(pageNumber)}>
            <li
              className={`${isActiveClassName} px-4 py-2 mx-1 font-semibold bg-white rounded-lg cursor-pointer hover:bg-gray-700 hover:text-gray-200`}
            >
              {pageNumber}
            </li>
          </Link>
        )
      })}
    </ul>
  )
}
