import React from "react"
import { graphql, PageProps, Link } from "gatsby"
import Image, { FluidObject } from "gatsby-image"
import { css } from "@emotion/core"
import { Star } from "react-feather"
import SwiperCore, { Autoplay, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"

import Layout from "components/layout"
import SEO from "components/seo"
import { Pagination } from "components/pagination"

SwiperCore.use([Autoplay, A11y])

type MenuColumn = {
  fields: {
    food_name: { text: string }
    food_description: string
    food_price: number
    food_is_chef_favorite: boolean
  }[]
  primary: {
    food_type_title: string
  }
}

type Data = {
  prismic: {
    currentCategory: {
      body: MenuColumn[]
      menu_type: "Food" | "Drinks"
      menu_color: string
    }
    images: {
      edges: {
        node: {
          carousel_images: {
            carousel_image: {
              alt: string
            }
            carousel_imageSharp: { childImageSharp: { fluid: FluidObject } }
          }[]
        }
      }[]
    }
    allCategories: {
      edges: {
        node: {
          _meta: {
            uid: string
          }
          menu_category_name: string
        }
      }[]
    }
  }
}

type CategoryInfo = {
  category: string
  numberOfPages: number
  humanPageNumber: number
  slug: string
  skip: number
  limit: number
}

const createURL = (slug: string) => (pageNumber: number) => {
  if (pageNumber == 1) {
    return `/menu/${slug}/`
  } else {
    return `/menu/${slug}/page/${pageNumber}`
  }
}

type ContextType = {
  data: Data
  location: Location
  pageContext: {
    categoryName: string
    slug: string
    skip: number
    limit: number
  }
}

const Data = React.createContext<ContextType>(null)

const CategoryTemplate = ({
  data,
  location,
  pageContext: {
    numberOfPages,
    humanPageNumber,
    category: categoryName,
    slug,
    skip,
    limit,
  },
}: PageProps<Data, CategoryInfo>) => {
  const contextData = {
    data,
    location,
    pageContext: {
      categoryName,
      slug,
      skip,
      limit,
    },
  }

  const menuColor = data.prismic.currentCategory.menu_color

  return (
    <Layout location={location}>
      <SEO title={`Menu: ${categoryName}`} />
      <Data.Provider value={contextData}>
        <Carousel />
        <div
          css={css`
            color: ${menuColor};
          `}
          className="flex flex-col items-center max-w-screen-xl px-12 py-8 mx-auto space-y-8"
        >
          <CategoriesLinks />
          <Menu />
          <Pagination
            createURL={createURL(slug)}
            totalPages={numberOfPages}
            currentPage={humanPageNumber}
          />
        </div>
      </Data.Provider>
    </Layout>
  )
}

const useData = () => {
  const data = React.useContext(Data)
  if (!data) {
    throw new Error("useData must be used inside a Data Provider")
  }
  return data
}

const Carousel = () => {
  const { data } = useData()

  const slideParams = {
    spaceBetween: -100,
    loop: true,
    loopedSlides: 2,
    centeredSlides: true,
    autoplay: true,
    slideToClickedSlide: true,
    breakpoints: {
      // when window width is >= 480px
      320: {
        slidesPerView: 1.4,
      },
      768: {
        slidesPerView: 2,
      },
    },
  }

  const carouselImages = data.prismic.images.edges[0].node.carousel_images

  return (
    <div
      css={css`
        div {
          transition: transform 0.4s ease-in-out;
        }

        .swiper-wrapper > div:not(.swiper-slide-active) {
          transform: scale(0.9);
        }

        .swiper-slide-active {
          z-index: 10;
        }

        .swiper-container {
        }
      `}
      className="pt-24"
    >
      <Swiper {...slideParams}>
        {carouselImages.map(
          ({ carousel_image: { alt }, carousel_imageSharp }, index) => (
            <SwiperSlide key={index}>
              <Image
                alt={alt}
                fluid={carousel_imageSharp.childImageSharp.fluid}
                className="rounded-md"
              />
            </SwiperSlide>
          )
        )}
      </Swiper>
    </div>
  )
}

const CategoriesLinks = () => {
  const { data, location } = useData()
  const categoryNames = data.prismic.allCategories.edges

  return (
    <div className="flex items-center justify-center mt-6 text-2xl sm:text-4xl sm:flex-row sm:space-x-12 font-display">
      {categoryNames.map(
        ({
          node: {
            menu_category_name,
            _meta: { uid },
          },
        }) => {
          const isActive = location.pathname.includes(`/menu/${uid}`)

          return (
            <Link
              key={uid}
              to={`/menu/${uid}`}
              className={`${
                isActive
                  ? "border-b-4 border-current text-current"
                  : "text-black"
              } mx-4 sm:mx-0`}
            >
              {menu_category_name}
            </Link>
          )
        }
      )}
    </div>
  )
}

const Menu = () => {
  const { data } = useData()
  const menuType = data.prismic.currentCategory.menu_type
  let MenuToRender = null

  switch (menuType) {
    case "Drinks":
      MenuToRender = DrinksMenu
      break
    case "Food":
      MenuToRender = FoodMenu
      break
    default:
      MenuToRender = FoodMenu
  }

  return <MenuToRender />
}

const FoodMenu = () => {
  const {
    data,
    pageContext: { skip, limit },
  } = useData()
  const currentCategory = data.prismic.currentCategory.body

  return (
    <div className="w-full">
      <div className="flex flex-col items-center space-y-3 text-center ">
        <div className="flex items-center justify-center ">
          <Star
            aria-hidden="true"
            className="mr-3 fill-current text-light-blue"
          />
          <p className="text-xl font-display">Chef's Favorite</p>
        </div>
        <p className="max-w-sm text-sm text-dark-brown">
          * Consuming raw or undercooked MEATS, or EGGS may increase your RISK
          of foodborne illness, especially if you have certain medical
          conditions
        </p>
      </div>
      <div
        css={css`
          grid-template-rows: repeat(2, fit-content());

          @media screen and (min-width: 426px) {
            grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
          }
        `}
        className="grid grid-flow-row grid-cols-1 col-gap-32"
      >
        {currentCategory
          .slice(skip, skip === limit ? undefined : limit)
          .map(({ fields, primary }, i) => (
            <section key={i} className="text-center">
              <header>
                <h1 className="text-5xl text-current sm:text-6xl font-display">
                  {primary.food_type_title}
                </h1>
                <p className="text-black opacity-50 text-md">
                  All substitutions add $2.50
                </p>
              </header>
              <div className="py-6 space-y-8">
                {fields.map(
                  (
                    {
                      food_description,
                      food_name,
                      food_is_chef_favorite: isFavorite,
                      food_price,
                    },
                    i
                  ) => (
                    <div
                      key={i}
                      className="flex flex-col items-center space-y-4"
                    >
                      <div className="flex flex-col flex-wrap sm:items-center sm:flex-row">
                        <div className="flex items-center">
                          {isFavorite && (
                            <span>
                              <Star
                                aria-hidden="true"
                                className="mr-3 fill-current text-light-blue"
                              />
                              <p className="sr-only">Chef's Favorite</p>
                            </span>
                          )}
                          <h1 className="text-3xl font-semibold">
                            <span>{food_name}</span>
                          </h1>
                        </div>
                        {food_price && (
                          <p className="max-w-lg text-xl font-semibold text-black sm:ml-6">
                            $ {food_price}
                          </p>
                        )}
                      </div>

                      {food_description && (
                        <p className="max-w-lg text-black">
                          {food_description}
                        </p>
                      )}
                    </div>
                  )
                )}
              </div>
            </section>
          ))}
      </div>
    </div>
  )
}

const DrinksMenu = () => {
  const {
    data,
    pageContext: { skip, limit },
  } = useData()
  const currentCategory = data.prismic.currentCategory.body

  return (
    <div
      css={css`
        grid-template-rows: repeat(2, fit-content());

        @media screen and (min-width: 426px) {
          grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        }
      `}
      className="grid grid-flow-row grid-cols-1 col-gap-32"
    >
      {currentCategory
        .slice(skip, skip === limit ? undefined : limit)
        .map(({ fields, primary }, i) => (
          <section key={i}>
            <header className="text-center">
              <h1 className="text-5xl text-current sm:text-6xl font-display">
                {primary.food_type_title}
              </h1>
              <p className="text-black opacity-50 text-md">
                All substitutions add $2.50
              </p>
            </header>
            <div className="py-6 space-y-8 text-center">
              {fields.map(({ food_description, food_name }, i) => (
                <span
                  key={i}
                  className="flex flex-col items-center space-y-4 text-black"
                >
                  <h1 className="text-3xl font-semibold text-dark-brown">
                    {food_name}
                  </h1>
                  {food_description && (
                    <p className="opacity-50">{food_description}</p>
                  )}
                </span>
              ))}
            </div>
          </section>
        ))}
    </div>
  )
}

export default CategoryTemplate

export const pageQuery = graphql`
  query MenuCategory($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    prismic {
      allCategories: allMenu_categorys {
        edges {
          node {
            _meta {
              uid
            }
            menu_category_name
          }
        }
      }
      currentCategory: menu_category(lang: "en-us", uid: $slug) {
        body {
          ... on PRISMIC_Menu_categoryBodyFood_type {
            fields {
              food_name
              food_description
              food_is_chef_favorite
              food_price
            }
            primary {
              food_type_title
            }
          }
        }
        menu_color
        menu_type
      }
      images: allMenu_carousels {
        edges {
          node {
            carousel_images {
              carousel_image
              carousel_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
